@import './colors';
hr{
  border: 0px;
  border-bottom: 3px solid $accent;
  width: 70%;
}
.hr-div{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.quiz-form-div{
  background-color: white;
  min-height: 100vh;
  padding-top: 40px;
  padding-left: 20px;
}