.questions {
  // background: $primary;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 60px;
  color: $grey-bg;
  
  cursor: pointer;
  &:hover {
    background: #d04b83;
    box-shadow: inset 0px 0px 4px 1px rgba(107, 8, 44, 0.1);
  }
  &-selected {
    background: $accent;
    box-shadow: inset 0px 0px 4px 1px rgba(107, 8, 44, 0.1);
  }
  &:active {
    background: $accent;
    box-shadow: inset 0px 0px 4px 1px rgba(107, 8, 44, 0.1);
  }
  &:disabled {
    background: $primary;
    color: rgba(197, 195, 210, 0.4);
  }
}
