.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-select-main {
  border-radius: 5px;
  position: fixed;
  background: white;
  color: black;
  width: 600px;
  max-height: 596px;
  overflow-y: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-title {
  margin-bottom: 10px;
  text-align: center;
}

.modal-select-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.modal-select {
  margin-bottom: 14px;
  padding: 5px;
  width: 100%;
  max-width: 90%;
  text-align: center;
  font-size: 14px;
  cursor: pointer;

  &-btns-div {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
}

.modal-profile-buttons-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}
