@import "./colors";

.quiz-form-title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: $accent;
}
.quiz-form-subtitle {
  color: $primary;
  margin: 10px 0px 20px 0px;
}
.quiz-form-question {
  &-div {
    background-color: $grey-bg;
    margin-bottom: 5px;
    color: $primary;
    margin-right: 20px;
    height: 100%;
    padding: 60px 0px 40px 60px;
  }
  &-number {
    color: $primary;
  }
  &-btns {
    padding: 40px 0px 0px 80px;
  }
  &-btn-submit {
    width: 300px;
    &-div {
      padding: 20px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &-img-div {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px 0px 20px 85px;
  }
  &-img-options-div {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px 0px 20px 0px;
    &-item{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  &-img {
    width: 415px;
    height: 227px;
    &-letter{
      text-align: center;
      border: 1px solid white;
      background-color: white;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      font-size: 25px;
      font-weight: bold;
      padding: 10px;
      margin-bottom: 20px;
    }
  }
}
