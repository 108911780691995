@import "./colors.scss";

.login-div,
.home-div,
.quiz-div {
  width: 100%;
  min-height: 100vh;
  color: white;
  background-image: url(../../public/assets/img/background1.png);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.item {
  margin: 20px;
}
.form-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 300px);
  justify-content: center;
  align-items: center;
}
.form-title {
  color: white;
}
.form-line {
  background-color: $accent;
  height: 5px;
  width: 101px;
  border: 0;
}
.form-inputs-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 80px;
}
.form-inputs {
  width: 520px;
  border: 0;
  background-color: transparent;
  padding: 10px 0px;
  color: white !important;
  cursor: text;
}
.form-label {
  margin-bottom: 20px;
  color: $primary-variant;
}
.form-submit-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}
.form-submit-btn {
  background: #f5f3f9;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  color: $grey-mid;
}
.form-hr {
  width: 570px;
  border: 0;
  border-bottom: 3px solid white;
  margin-bottom: 40px;
  &-success {
    width: 570px;
    border: 0;
    margin-bottom: 40px;
    border-bottom: 3px solid #11f2d7;
  }
  &-error {
    width: 570px;
    border: 0;
    margin-bottom: 40px;
    border-bottom: 3px solid #f6e9a2;
  }
}

.form-icon {
  margin-left: 20px;
}
.btn-microsoft {
  font-size: 20px !important;
  cursor: pointer;
  color: white;
  &:hover {
    color: $accent;
  }
}
